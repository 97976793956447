<template>
  <div>
    <v-card
      v-show="show"
      class="my-card-style"
    >
      <v-container fluid>
        <v-radio-group
          v-model="week"
          row
        >
          <v-radio
            label="Daily"
            value="daily"
          />
          <v-spacer />
          <v-radio
            label="Weekdays"
            value="weekdays"
          />
          <v-spacer />
          <v-radio
            label="Weekends"
            value="weekends"
          />
        </v-radio-group>
        <hr>
        <v-row>
          <v-col sm="2">
            <v-checkbox
              v-model="days.mon"
              label="Monday"
              color="#FF3700"
            />
          </v-col>
          <v-col sm="2">
            <v-checkbox
              v-model="days.tue"
              label="Tuesday"
              color="#FF3700"
            />
          </v-col>
          <v-col sm="2">
            <v-checkbox
              v-model="days.wed"
              label="Wednesday"
              color="#FF3700"
            />
          </v-col>
          <v-col sm="2">
            <v-checkbox
              v-model="days.thu"
              label="Thursday"
              color="#FF3700"
            />
          </v-col>
          <v-col sm="2">
            <v-checkbox
              v-model="days.fri"
              label="Friday"
              color="#FF3700"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="2">
            <v-checkbox
              v-model="days.sat"
              label="Saturday"
              color="#FF3700"
            />
          </v-col>
          <v-col sm="2">
            <v-checkbox
              v-model="days.sun"
              label="Sunday"
              color="#FF3700"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <v-dialog
              ref="startDialog"
              v-model="startClock"
              :return-value.sync="start"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="start"
                  label="Start Time"
                  prepend-icon="mdi-clock-time-four-outline"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-time-picker
                v-if="startClock"
                v-model="start"
                color="#37474F"
                :min="end"
                full-width
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="startClock = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.startDialog.save(start)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <v-dialog
              ref="endDialog"
              v-model="endClock"
              :return-value.sync="end"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="end"
                  label="End Time"
                  prepend-icon="mdi-clock-time-four-outline"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-time-picker
                v-if="endClock"
                v-model="end"
                color="#37474F"
                :min="start"
                full-width
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="endClock = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.endDialog.save(end)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-btn
            class="schedule-btn ma-2"
            color="red darken-3"
            depressed
            default
            rounded
            @click="close()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            class="schedule-btn ma-2"
            color="#FF3700"
            depressed
            default
            rounded
            :disabled="!disableSave"
            @click="submit()"
          >
            Save
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        start: '',
        end: '',
        startClock: false,
        endClock: false,
        week: false,
        days: {
          sun: false,
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
        },
      };
    },
    computed: {
      show: {
        get: function () {
          return this.value;
        },
        set: function (value) {
          this.$emit('input', value);
        },
      },
      disableSave () {
        return Object.values(this.days).some(val => val === true) && this.start && this.end;
      },
    },
    watch: {
      week: function (newval) {
        if (newval === 'weekends') {
          this.days.sun = true;
          this.days.mon = false;
          this.days.tue = false;
          this.days.wed = false;
          this.days.thu = false;
          this.days.fri = false;
          this.days.sat = true;
        }
        if (newval === 'weekdays') {
          this.days.sun = false;
          this.days.mon = true;
          this.days.tue = true;
          this.days.wed = true;
          this.days.thu = true;
          this.days.fri = true;
          this.days.sat = false;
        }
        if (newval === 'daily') {
          this.days.sun = true;
          this.days.mon = true;
          this.days.tue = true;
          this.days.wed = true;
          this.days.thu = true;
          this.days.fri = true;
          this.days.sat = true;
        }
      },
    },
    methods: {
      submit () {
        this.$store.dispatch('schedule/fetchAdvancedScheduleData', {
          days: {
            sun: this.days.sun,
            mon: this.days.mon,
            tue: this.days.tue,
            wed: this.days.wed,
            thu: this.days.thu,
            fri: this.days.fri,
            sat: this.days.sat,
          },
          start: this.start,
          end: this.end,
        });
        this.close();
      },
      close () {
        this.week = false;
        this.start = '';
        this.end = '';
        this.show = false;
        this.days.sun = false;
        this.days.mon = false;
        this.days.tue = false;
        this.days.wed = false;
        this.days.thu = false;
        this.days.fri = false;
        this.days.sat = false;
      },
    },
  };
</script>
<style scoped>
.my-card-style {
  border-radius: 25px;
}
.schedule-btn {
  width: 20px;
}
</style>
