import { walkTreeData } from 'he-tree-vue';

export function getDefaultStructure (digiBoards) {
    const defaultStructure = [{ text: 'All Boards', children: [] }];
    const allDigiBoards = digiBoards;
    const structure = defaultStructure;
    for (const digiboard of allDigiBoards) {
        structure[0].children.push({
            id: digiboard.digi_board.external_id,
            text: `${digiboard.digi_board.external_id} - ${digiboard.digi_board.location}`,
        });
    }
    return structure;
};

export function addMissingBoards (treeData, digiBoards) {
    const availableBoards = [];
    walkTreeData(treeData, (node) => {
        if (node.id !== undefined) {
            availableBoards.push(node.id);
        }
    });
    const newBoards = digiBoards.filter(i => !availableBoards.includes(i.digi_board.external_id));
    for (const newBoard of newBoards) {
        treeData[0].children.push({
            id: newBoard.digi_board.external_id,
            text: `${newBoard.digi_board.external_id} - ${newBoard.digi_board.location}`,
        });
    }
    return treeData;
};
